const gtagConsentStatusKey = 'gtagConsentStatus';

export function allConsentGranted() {
	console.log('consent granted');
	updateConsents({ analyticsAllowed: true });
}

export function allConsentDenied() {
	console.log('consent denied');
	updateConsents({ analyticsAllowed: false });
}

export interface ConsentPreferences {
	analyticsAllowed: boolean | undefined;
}

/** Set consent preferences in localStorage and pass them to scripts (e.g. Google Analytics) */
export function updateConsents(consentPreferences: ConsentPreferences) {
	console.log('updating consent:', consentPreferences);

	localStorage.setItem(gtagConsentStatusKey, JSON.stringify(consentPreferences));

	passConsentsToScripts(consentPreferences);
}

/** Pass some consent preferences to scripts such as Google Analytics */
function passConsentsToScripts(consentPreferences: ConsentPreferences) {
	gtag('consent', 'update', {
		ad_user_data: 'denied',
		ad_personalization: 'denied',
		ad_storage: 'denied',
		analytics_storage: consentPreferences.analyticsAllowed === true ? 'granted' : 'denied'
	});
}

/** Returns the current consent status after attempting to get it from local storage */
export function getConsentStatusFromStorage(): ConsentPreferences | undefined {
	const localStorageResult = localStorage.getItem(gtagConsentStatusKey);
	console.log('current status from storage:', localStorageResult);

	if (localStorageResult !== null) {
		// if the consent status has previously been set, use it now
		const consentStatus = JSON.parse(localStorageResult) as ConsentPreferences;

		return consentStatus;
	} else {
		return undefined;
	}
}

export function isConsentSettingUpToDate(consentSettingValue: ConsentPreferences): boolean {
	if (typeof consentSettingValue.analyticsAllowed !== 'boolean') {
		return false;
	}

	return true;
}

/** Returns the current consent status after attempting to get it from local storage,
 * and setting any required consent statuses (e.g. with gtag) */
export function initialiseConsentStatus(): ConsentPreferences | undefined {
	console.log('loading previously set consent options');

	const consentStatus = getConsentStatusFromStorage();

	if (consentStatus !== undefined) {
		try {
			passConsentsToScripts(consentStatus);
		} catch (error) {
			console.log(error);
			localStorage.removeItem(gtagConsentStatusKey);

			return undefined;
		}
	}

	return consentStatus;
}
